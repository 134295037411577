<template>
	<div class="filter__wrapper" v-if="data">
		<div class="filter__mobile-box" :class="{ active: show }">
			<div class="filter__mobile-wrapper flex flex--middle">
				<button class="filter__close-mobile" @click="hideFiltersMethod">
					<span class="visually-hidden">Закрыть фильтр</span>
				</button>
				<button class="header__close" @click="hideFiltersMethod">
					<span class="visually-hidden">Закрыть фильтр</span>
				</button>
				<ul class="grid__filter-list mr-auto" v-show="data.list">
					<li class="grid__filter-item">
						<button
							class="grid__filter-button"
							:class="{ active: getApplyedFilterCity }"
							@click="filterSelectAll"
						>
							Все
						</button>
					</li>
					<li class="grid__filter-item">
						<button
							class="grid__filter-button"
							:class="{ active: !getApplyedFilterCity }"
							@click="filterSelectCity"
						>
							В вашем городе
						</button>
					</li>
				</ul>
				<Filters
					v-for="(filter, index) in data.filters"
					:key="index"
					:data="filter"
					:card="data.cardType"
					:store="data.store"
					:type="data.type"
					@change="onchange"
				></Filters>
			</div>
		</div>

		<button class="button filter__mobile-button" @click="showFiltersMethod">
			Фильтр
		</button>
	</div>
</template>

<script>
import Filters from '@/components/Filters'

export default {
	name: 'FilterBox',
	components: {
		Filters,
	},

	props: {
		data: {
			type: Object,
			required: true,
		},
		show: {
			type: Boolean,
			required: false //todo пока false
		}
	},

	data() {
		return {
			showFilters: false,
		}
	},

	methods: {
		filterSelectAll() {
			this.$store.dispatch('SET_APPLIED_CITY', 'all')
		},

		filterSelectCity() {
			this.$store.dispatch('SET_APPLIED_CITY', 'selected')
		},

		hideFiltersMethod() {
			this.$emit('change:show', false)
			this.$store.dispatch('SET_BLOCK_SCROLL', false)
		},

		showFiltersMethod() {
			this.$emit('change:show', true)
			this.$store.dispatch('SET_BLOCK_SCROLL', true)
		},

		onchange(data) {
			this.$emit('change', data)
			this.hideFiltersMethod()
		},
	},
	computed: {
		getApplyedFilterCity() {
			return this.$store.getters.APPLIED_CITY == 'all' ? true : false
		},
	},

	mounted() {
		this.$root.$on('hideFilterBox', () => {
			this.hideFiltersMethod()
		})
	},

	beforeDestroy() {
		this.$store.dispatch('SET_APPLIED_CITY', 'all')
	},
}
</script>
