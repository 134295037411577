<template>
	<div class="filter" v-click-outside="hideList" v-if="data">
		<button
			class="filter__button button"
			:class="{ active: showBox }"
			@click="showList"
		>
			{{ data.name }}
		</button>
		<transition name="filter__slide">
			<form
				class="filter__box"
				v-show="showBox"
				@submit.prevent="applyFilters"
				@mouseover="mouseOver"
				@mouseleave="mouseLeave"
			>
				<div
					class="filter__wrapper-box"
					v-for="(filter, id) in data.data"
					:key="id"
					v-show="!(filter.title == 'Тип карты' && card)"
				>
					<p class="filter__heading" v-if="filter.title">{{ filter.title }}</p>
					<perfect-scrollbar  :options="{suppressScrollX:true, useBothWheelAxes:false}" class="filter__scroll">
						<ul class="filter__list">
							<li class="filter__item" v-if="filter.all" :key="index">
								<label class="filter__label checkbox">
									<input
										class="visually-hidden"
										:value="true"
										type="radio"
										v-model="selectAll"
										@change="selectAllChange"
									/>
									<span class="checkbox__indicator"></span>
									<span class="checkbox__text">Все</span>
								</label>
							</li>
							<li
								class="filter__item"
								v-for="(item, index) in filter.list"
								:key="index"
								v-show="(filter.cardType || type !== 'points') || (item.hasOwnProperty('isMain') && item.isMain)"
							>
								<label class="filter__label checkbox">
									<template v-if="filter.type == 'radio'">
										<input
											class="visually-hidden"
											:value="item.id"
											type="radio"
											:name="`radio-${id}`"
											v-model="radioChecked"
										/>
									</template>
									<template v-else>
										<input
											v-show="!card"
											v-if="filter.cardType"
											class="visually-hidden filter__card-type"
											:value="item.id"
											type="checkbox"
											v-model="cardType"
											@change="radioChange"
										/>
										<input
											v-else
											class="visually-hidden filter__card-point"
											:value="item.id"
											type="checkbox"
											v-model="checkBoxChecked"
											@change="radioChange"
										/>
									</template>
									<span class="checkbox__indicator"></span>
									<span class="checkbox__text">{{ item.name }}</span>
								</label>
							</li>
						</ul>
					</perfect-scrollbar>
				</div>
				<div class="filter__button-box">
					<button class="button" type="submit">
						Показать
					</button>
				</div>
			</form>
		</transition>
	</div>
</template>

<script>
export default {
	name: 'Filters',

	props: {
		data: {
			type: Object,
			required: true,
		},
		card: {
			type: String,
		},
		type: {
			type: String
		},
		store: {
			type: Object,
			required: true,
		},
	},

	data() {
		const data = {
			pointsCommercial: { id: [], points: [] },
			pointsSocial: { id: [], points: [] },
			//
			showBox: false,
			checkBoxChecked: [],
			cardType: [],
			selectAll: true,
			radioChecked: '',
		}
		return data
	},

	methods: {
		showList() {
			this.showBox ? (this.showBox = false) : (this.showBox = true)
		},

		hideList() {
			this.showBox = false
		},

		applyFilters() {
			let checkedPointIds = []
			let additionalPoints = []
			
			if(this.data.data.length > 1){
				additionalPoints = this.data.data[1].list?.filter((point)=>!point.isMain) || []
			} else {
				// this.data.data[0].list.filter((point)=>!point.isMain)
			}
			if(this.checkBoxChecked.includes(0)) checkedPointIds = [...this.checkBoxChecked, ...additionalPoints.map((point)=>point.id)]
			else checkedPointIds = this.checkBoxChecked
			
			let data = {
				all: this.selectAll,
				list: checkedPointIds,
				cardType: this.data.cardType ? [this.data.cardType] : this.cardType,
			}
			this.$emit('change', data)
			this.$root.$emit('hideFilterBox')
			
			this.$store.dispatch(this.store.dispatch, data)
			this.showBox = false
			let points = []
			if (this.selectAll) {
				points = [...this.getPoints];
			}
			else {
				const isBankCardIssuer = this.cardType.some(ct => ct === 'isBankCardIssuer')
				const isNonFinancialCardIssuer = this.cardType.some(ct => ct === 'isNonFinancialCardIssuer')
				const anyIssuer = this.cardType.length === 0 || (isBankCardIssuer && isNonFinancialCardIssuer)
				
				if (checkedPointIds.length > 0) {
					points = this.getPoints.filter(point =>
						(
							anyIssuer ||
							(isBankCardIssuer && point.organization.isBankCardIssuer) ||
							(isNonFinancialCardIssuer && point.organization.isNonFinancialCardIssuer)
						) &&
						checkedPointIds.includes(point.organization.id)
					)
				} else {
					points = this.getPoints.filter(point =>
						(isBankCardIssuer && point.organization.isBankCardIssuer) ||
						(isNonFinancialCardIssuer && point.organization.isNonFinancialCardIssuer)
					)
				}
			}

			try {
				this.$root.$emit('filteredCity', points)
			} catch(e) {
				console.log(e)
			}
		},

		radioChange() {
			if (this.selectAll && this.checkBoxChecked.length > 0) {
				this.selectAll = false
			} else if (!this.selectAll && this.checkBoxChecked.length == 0) {
				this.selectAll = true
			}
		},

		selectAllChange() {
			if (this.selectAll) {
				this.checkBoxChecked = []
			}
		},

		mouseOver() {
			if (!this.$store.getters.VISUALLY_IMPAIRED) {
				this.$store.dispatch('SET_BLOCK_SCROLL', true)
			}
		},

		mouseLeave() {
			this.$store.dispatch('SET_BLOCK_SCROLL', false)
		},
	},
	computed: {
		getPoints() {
			return this.$store.getters.POINTS_ORGANIZATION || []
		},
		getCity() {
			return this.$store.getters.PICKEDCITY
		},
	},
	created() {
		this.$nextTick(() => {
			if (this.card) {
				this.cardType = []
				this.cardType.push(this.card)
			}
		})
	},
	mounted() {
		this.$store.dispatch('GET_POINTS_ORGANIZATION')
			.then(() => this.applyFilters())
	},
	beforeDestroy() {
		const data = {
			all: true,
			list: [],
			cardType: [],
		}

		this.$store.dispatch(this.store.dispatch, data)
	},
}
</script>
