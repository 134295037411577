<template>
	<main class="main">
		<section class="container">
      <Crumbs :data="crumbs"></Crumbs>

			<Partners class="stocks" :data="stocks" v-if="stocks"></Partners>
		</section>
	</main>
</template>

<script>
import Partners from '@/components/Partners.vue'
import Crumbs from '@/components/Crumbs'

export default {
	name: 'Stocks',

	components: {
		Partners,
		Crumbs,
	},

	data() {
		return {
			crumbs: null,
    }
	},

	computed: {
		stocks() {
			const stocksList = this.$store.getters.LIST_LOYALTY_PROGRAMS
			const acceptors = this.$store.getters.LIST_ACCEPTORS

			let data = stocksList

			if (stocksList && acceptors) {
				data = []

				stocksList.forEach(stock => {
					acceptors.forEach(acceptor => {
						if (stock.acceptorId == acceptor.id) {
							stock.acceptorInfo = acceptor

							data.push(stock)
						}
					})
				})
			}

			if (stocksList) {
				return {
					title: 'Акции',
					link: 'StocksItem',
					filterDispatch: 'SET_LIST_APPLIED_STOCKS_FILTERS',
					filterGetters: 'LIST_APPLIED_STOCKS_FILTERS',
					filterAll: true,
					list: data,
				}
			} else {
				return null
			}
		},
	},

	mounted() {
		this.$store.dispatch('GET_LIST_LOYALTY_PROGRAMS')

		this.$store.dispatch('GET_LIST_ACCEPTORS')

    this.crumbs = [
      {
        link: 'HolderHome',
        text: 'Главная',
      },
      {
        link: null,
        text: 'Акции',
      },
    ]
	},
}
</script>
